<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation>
                        <v-expansion-panels
                                accordion
                                v-model="panel"
                                multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.profile') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.accountOwner') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-autocomplete
                                                    v-model="bank_account.supplier_id"
                                                    solo
                                                    required
                                                    :label="bank_account.supplier_id ? '' : $t('message.required')"
                                                    hide-details
                                                    background-color="white"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="suppliers"
                                            ></v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.displayName') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.displayname"
                                                    solo
                                                    required
                                                    :label="bank_account.displayname ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.currency') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-select
                                                    v-model="bank_account.currency_id"
                                                    solo
                                                    required
                                                    :items=currencies
                                                    item-text="code"
                                                    item-value="id"
                                                    :label="bank_account.currency_id ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.bankInfo') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.bankName') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.title"
                                                    solo
                                                    required
                                                    :label="bank_account.title ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.address') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-textarea
                                                    v-model="bank_account.address"
                                                    solo
                                                    required
                                                    :label="bank_account.address ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.country') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-autocomplete
                                                    :label="bank_account.address ? '' : $t('message.required')"
                                                    :items="countries"
                                                    clearable
                                                    solo
                                                    background-color="white"
                                                    hide-details
                                                    item-text="name"
                                                    item-value="id"
                                                    v-model="bank_account.country_id"
                                            >
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.accountDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.beneficiary') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.beneficiary"
                                                    solo
                                                    required
                                                    :label="bank_account.beneficiary ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.accountNumber') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.account"
                                                    solo
                                                    required
                                                    :label="bank_account.account ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.swiftCode') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.swiftcode"
                                                    solo
                                                    required
                                                    :label="bank_account.swiftcode ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.abaNumber') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.abano"
                                                    solo
                                                    :label="bank_account.abano ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.agentBankDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.agentName') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.agent"
                                                    solo
                                                    :label="bank_account.agent ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.agentSwiftCode') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.agentswiftcode"
                                                    solo
                                                    :label="bank_account.agentswiftcode ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.agentAccount') }}</v-flex>
                                        <v-flex xs6 md3 py-1>
                                            <v-text-field
                                                    v-model="bank_account.beneficiaryaccount"
                                                    solo
                                                    :label="bank_account.beneficiaryaccount ? '' : $t('message.required')"
                                                    hide-details
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3>
                                    <v-btn
                                            color="warning"
                                            @click.stop="previewDialog = true"
                                            small
                                    >{{ $t('message.previewProfile') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                v-model="previewDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
        >
            <v-card>
                <v-card-title>
                    <v-toolbar
                            color="success"
                            dark
                            flat
                            dense
                    >
                        <v-btn icon @click.native="previewDialog = false" dark>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t('message.summary') }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout row>
                            <v-flex xs12 sm6>
                                <v-layout row>

                                </v-layout>
                            </v-flex>
                            <v-flex xs11 sm5>
                                <v-layout row>

                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error lighten-2" @click.stop="close">{{$t('message.backToEditing')}}</v-btn>
                    <v-btn color="primary lighten-2" @click.stop="save">{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { api } from "../../../api";
    export default {
        data() {
            return {
                loader: true,
                bank_account: {},
                openSection: false,
                previewDialog: false,
                panel: [0],
                suppliers: [],
                currencies: [],
                countries: [],
                ar_suppliers: [],
                ar_currencies: [],
                ar_countries: []
            }
        },
        name: "Profile",
        props: ["ba_id"],
        mounted(){
            this.getBankAccountData(this.$props.ba_id);
            this.getSuppliers();
            this.getCurrencies();
            this.getCountries();
        },
        methods: {
            getBankAccountData(ba_id) {
                api
                    .get("appic/getbankaccount/" + ba_id)
                    .then(response => {
                        this.loader = false;
                        this.bank_account = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getSuppliers() {
                api
                    .get("appic/getvendors")
                    .then(response => {
                        this.loader = false;
                        this.suppliers = response.data;
                        var ar_suppliers = [];
                        this.suppliers.forEach(function(item) {
                            ar_suppliers[item.id] = item.name;
                        });
                        this.ar_suppliers = ar_suppliers;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getCurrencies() {
                api
                    .get("appic/getcurrencies")
                    .then(response => {
                        this.loader = false;
                        this.currencies = response.data;
                        var ar_currencies = [];
                        this.currencies.forEach(function(item) {
                            ar_currencies[item.id] = item.name;
                        });
                        this.ar_currencies = ar_currencies;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getCountries() {
                api
                    .get("appic/getcountries")
                    .then(response => {
                        this.loader = false;
                        this.countries = response.data;
                        var ar_countries = [];
                        this.countries.forEach(function(item) {
                            ar_countries[item.id] = item.name;
                        });
                        this.ar_countries = ar_countries;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            save () {
                if(this.$refs.form.validate()) {
                  this.createDialog = false;
                  this.$refs.form.reset();
                }
            },
            close () {
                this.createDialog = false;
                this.$refs.form.reset()
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true
                }
            },
            reset () {
                this.$refs.form.reset()
            },
            resetValidation () {
                this.$refs.form.resetValidation()
            },
            resetExpandPanel () {
                this.panel = [];
            }
        }
    }
</script>

<style scoped>

</style>
